import React from "react"
import "./Covid19.scss"
import HeroBanner from "../../components/HeroBanner"
import Layout from "../../components/Layout"
import OcrSeparator from "../../components/OcrSeparator"
import { ListGroup, ListGroupItem } from "reactstrap"
import { graphql } from "gatsby"

const Rules = ({ data }) => {
  return (
    <Layout>
      <div className="Rules">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="Pandemic Handbook for OCRA"
          caption="Our Pandemic guidelines for our new normal."
        />
        <div className="container content">
          <h2>EXECUTIVE SUMMARY:</h2>
          <div className="indented">
            <p>
              OCR Academy is your Obstacle course fitness destination where we
              service over 1000 families a week. Our goal is to facilitate
              fitness that is fun for the whole family. We overcome lifes
              challenges one obstacle at a time.
            </p>
            <p>
              <strong>Benefits of Exercise:</strong> While not specifically
              outlined in this document, it should be noted that regular
              exercise is one of the most beneficial activities an individual
              can undertake to improve their health.
            </p>
            <p>
              Normally, the list of the benefits of exercise is wide and varied
              – everything from being protective against stroke, heart disease
              and many other chronic diseases and conditions, all the way
              through to its role in weight management and diabetes control.
            </p>
            <p>
              However, right now the focus is around mental health, and its
              proven benefits for mental health, which is protective against
              both depression and anxiety – two looming issues in the COVID-19
              environment.
            </p>
            <p>
              It should be mentioned that COVID-19 seems to pose an increased
              risk to Canadians who have underlying health concerns, many of
              which fall under various categories of metabolic disease. Regular
              exercise is well known to combat many of these ailments.
            </p>
          </div>
          <OcrSeparator />
          <h2>OBJECTIVES</h2>
          <div className="indented">
            <p>The Objectives of this framework are:</p>
            <ol>
              <li>
                We have developed a framework that provides a safe environment
                for participants to return to OCR Academy that meets both
                Ontario Government standards, but also considers international
                and national best practices.
              </li>
              <li>
                To provide “Trust and Confidence” to both the Government and the
                Ontario public, that OCR Academy take COVID-19 safety seriously
                and is taking all practical steps to mitigate this serious
                issue.
              </li>
            </ol>
          </div>
          <OcrSeparator />
          <h2>ASSUMPTIONS:</h2>
          <div className="indented">
            <p>
              All participants in a facility are treated as if they may be
              infected with COVID-19.
            </p>
            <p>
              Restrictions in Government operations will be in place for the
              foreseeable future, thus requiring OCR Academy to create
              sustainable changes to our operational strategies.
            </p>
          </div>
          <OcrSeparator />
          <h2>01 Containment and contact tracing:</h2>
          <div className="indented">
            <p>
              <strong>A. Control Access:</strong> OCRA will have at least two
              staff members present when open. Maintaining physical distancing
              cannot be ensured if patrons have uncontrolled access.
              Additionally, staff are needed to ensure that disinfection of
              equipment is being performed after each use.
            </p>
            <p>
              <strong>B. Unit Occupancy:</strong> The number of patrons allowed
              into the facility has not been identified yet for Ontario but
              other provinces seem to suggest that a maximum capacity of 10:
              <ol type="i">
                <li>
                  We ensure there is sufficient space to allow patrons to remain
                  two metres from one another.
                </li>
                <li>We will prevent large gatherings. </li>
                <li>
                  Allow staff sufficient time to clean equipment and other
                  surfaces frequently.
                </li>
              </ol>
            </p>
            <p>
              <strong>C. Post Signs:</strong> Signs are posted at front entrance
              to notify patrons that they should not enter if they have the
              symptoms of COVID-19 (see “D” below). Signs will be posted
              throughout the facility to make patrons aware of the physical
              distancing requirements, enhanced sanitation procedures (including
              reminders for members to wipe equipment before and after each
              use), and any other instructions and limitations, as applicable.
            </p>
            <p>
              <strong>D. Screen Arriving Patrons:</strong> Patrons can be asked
              the following questions when they arrive:
              <ul>
                <li>
                  Have you or anyone in your household had the following
                  symptoms over the last 24 hrs?
                  <ul>
                    <li>Fever over 100.4</li>
                    <li>Shortness of breath</li>
                    <li>Coughing</li>
                  </ul>
                </li>
                <li>
                  Have you or anyone in your family worked in close proximity
                  with or on someone with confirmed Covid-19 in the last 14
                  days?
                </li>
              </ul>
            </p>
            <p>
              Any patron who answers yes to either of the questions above should
              not be permitted to enter. It is recommended that anyone who has
              the symptoms of COVID-19 call 8-1-1.
            </p>
            <p>
              <strong>E. Manage Patron Entry Points:</strong> We have placed
              markers such as tape or cones every two metres at entrances to
              provide patrons with visible queues that encourage physical
              distancing.
            </p>
            <p>
              <strong>F. Manage Equipment Access:</strong> Where possible, space
              between cardio machines has been increased to 2 meters to achieve
              adequate distancing between users, as well work out stations have
              been marked, 6x8 feet areas have been set up in the gym.
            </p>
            <p>
              <strong>
                G. Encourage physical distancing and discourage congregating:
              </strong>{" "}
              Patrons should be encouraged to conduct their workout and exit the
              facility without unnecessary delay. Exit will be beside warped
              wall.
              <ol>
                <li>
                  <strong>
                    Group Classes must accommodate the 2-metre rule at all times
                    during the session:
                  </strong>{" "}
                  For our group classes, we are scheduling to allow staff
                  adequate time to clean equipment and surfaces before each
                  class.
                </li>
                <li>
                  <strong>
                    Wherever applicable, do not offer food service
                  </strong>
                </li>
                <li>
                  <strong>Close patron water fountains:</strong> Patrons should
                  be advised to bring their own filled water bottles or use
                  touchless filling stations provided by the facility.
                </li>
              </ol>
            </p>
            <p>
              <strong>H. Developed online fitness sessions:</strong> We will
              continue to offer Zoom classes 5 days a week when we open
              Monday-Friday at 8am. The links will be available for 24hrs
            </p>
            <p>
              <strong>I. Masks:</strong> Staff and customers will not be
              required to wear masks while physical distancing is available. If
              a trainer needs to enter someone’s work out station, they will ask
              permission first.
            </p>

            <p>
              <strong>
                OCR Academy is very well equipped to both contain the spread of
                the virus within a facility, we have contracted Cintas with
                providing us with the industry standard for cleaning our
                facility and we have contracted Y & M Cleaning to come in once a
                week to clean entire facility.
              </strong>
            </p>
          </div>

          <OcrSeparator />

          <h2>02 Cleaning and Sanitization</h2>
          <div className="indented">
            <ol>
              <li>
                <strong>
                  Members will be encouraged to sanitize their equipment before
                  and after use.
                </strong>{" "}
                Disinfectants and micro fibre towel will be provided to each
                member as they walk in
              </li>
              <li>
                <strong>Use appropriate disinfectants.</strong> We have been
                provided disinfectants from Cintas that meet the regulations
                from Heath Canada. Our cleaning room has been outfitted with a
                diluting system so each bottle has the correct amount of water
                to cleaning solution ratio.
              </li>
            </ol>
            <h2>
              02.1 Staff will perform routine sanitization of high touch items
            </h2>
            <p>
              Such items include door handles, taps, cardiovascular equipment
              consoles. Including all of the obstacles that were involved in
              that class.
            </p>
            <ul>
              <li>
                Studios will be thoroughly cleaned after each class and classes
                will be scheduled to accommodate sufficient cleaning between
                them.
              </li>
              <li>
                Hard to clean equipment will be removed such as foam rollers,
                yoga blocks, straps, ropes, etc. Shared equipment (boxing
                gloves) will be removed.
              </li>
              <li>Carpets will be vacuumed daily. </li>
              <li>
                Cleaning logs will be kept and made available upon request.
              </li>
              <li>
                Staff who are undertaking deep cleans will be provided with PPE
                such as gloves and masks.
              </li>
              <li>
                Every week Y and M cleaning company will come in and clean
                entire facility from top to bottom with disinfectant.
              </li>
            </ul>
          </div>
          <OcrSeparator />

          <h2>
            03 STAFFING – TO ENSURE STAFF SAFETY, THE FOLLOWING GUIDELINES NDD
          </h2>
          <div className="indented">
            <ol>
              <li>
                Contactless interactions for both checking into a facility and
                providing equipment.
              </li>
              <li>
                Staff who feel unwell would be expected to stay home and
                self-quarantine for 14 days.
              </li>
              <li>
                Staff will need to be trained to follow all new operating
                procedures.
              </li>
            </ol>
          </div>
          <OcrSeparator />

          <h2>04 Operations</h2>
          <div className="indented">
            <ol>
              <li>
                Checking-in to the facility
                <ol type="i">
                  <li>
                    Check in and register via the OCRA APP. An automatic email
                    will be sent to customer with instructions. Email template
                    below in Operation Material
                  </li>
                  <li>Contactless check-in.</li>
                  <li>Hand Sanitize before entry into building</li>
                  <li>
                    For adult and kid classes, grab disinfectant bottle and head
                    to your designated 6x8 work out area when your instructor
                    will advise you on what equipment you will need.
                  </li>
                  <li>
                    Kids camp must go see group leader. You will be notified
                    once you arrive who your leader is.
                  </li>
                </ol>
              </li>
              <li>
                Cardiovascular Equipment
                <ol type="i">
                  <li>Spacing to accommodate physical distancing.</li>
                  <li>Cleaning of high touch surfaces.</li>
                </ol>
              </li>
              <li>
                Strength Training
                <ol type="i">
                  <li>Spacing to accommodate for physical distancing.</li>
                  <li>
                    Directional floor markers to assist with traffic flow.
                  </li>
                  <li>
                    Removal of hard to clear items such as foam roller, ropes,
                    etc.
                  </li>
                  <li>Studio clean before and after each class.</li>
                </ol>
              </li>
              <li>
                Obstacle Course
                <ol type="i">
                  <li>Spacing to accommodate physical distancing.</li>
                  <li>Liquid Chalk to be worn while on course.</li>
                  <li>
                    Course will be open in stages, If its not in the class, its
                    off limits.
                  </li>
                  <li>Studio clean before and after each class.</li>
                </ol>
              </li>
              <li>
                Staff and wearing Masks:
                <p>
                  We are not asking staff to wear masks as long as they can
                  maintain social distancing, if they are required to get inside
                  a clients bubble, the staff is required to wear a mask.
                </p>
              </li>
            </ol>
          </div>

          <OcrSeparator />

          <h2>05 Operation Material</h2>
          <div className="indented">
            <h3>1.0 Group classes</h3>

            <p>
              Every client who comes into the gym will need to do so by booking
              on our app. Once they have booked a session, the following email
              will be sent to them:
            </p>

            <div className="indented">
              <p>Welcome back!!</p>

              <p>The new normal is here, So that means new procedures.</p>

              <ol>
                <li>
                  Please arrive 15 mins before your class in your fitness gear
                  as the change rooms are closed. Also no out door shoes allowed
                  on the course.
                </li>
                <li>
                  Please remain in your car till a staff member directs you into
                  the building. Your car is the new waiting room. We will be
                  processing 3 people at a time.
                </li>
                <li>
                  Once inside please sanitize hands and stay 6 feet apart.
                </li>
                <li>
                  You are required to check in at the front desk where a staff
                  member has just a few questions for you.
                </li>
                <li>
                  Once you are done at front desk head into gym where our
                  trainer will indicate what you will need for the class and you
                  can go get your area set up.
                </li>
                <li>
                  We ask that you grab a cleaning bottle from the cafe as you
                  will be required to clean your equipment and area once you are
                  done.
                </li>
                <li>
                  Water station is closed, please bring your own water bottle.
                  If you don’t have one, we have some for sale #SupportLocal
                </li>
                <li>
                  Liquid chalk is mandatory for everyone on an obstacle course.
                  It contains 99% alcohol and is good for 100 uses, cost is $15.
                </li>
                <li>
                  Once you are done your class no socializing, please exit by
                  the warped wall.
                </li>
              </ol>

              <p>
                Thank you for your patience, support and above all else the
                honour of being your fitness provider.
              </p>

              <p>
                Yours in fitness,
                <br />
                Team OCRA
              </p>
            </div>

            <h3>2.0 Open Gym</h3>

            <p>
              We will be offering open gym, we will have a capacity of 10 people
              per 1hr time slots. Each person will be responsible for signing
              themselves into the selected time slot via the app. If they are
              registering multiple people, they must:
            </p>
            <ol>
              <li>Confirm that the client has a waiver filled out</li>
              <li>Purchase the amount of visits they require.</li>
              <li>
                Book themselves into select time slot, if they require multiple
                visits, Contact us via phone where a staff member will add the
                additional people.
              </li>
              <li>
                Once they have booked a time slot the following email will be
                sent to them with the instructions for entering and exiting the
                building as well as policies and procedures.
              </li>
            </ol>

            <h3>2.1 Email for Open gym</h3>

            <p>The new normal is here, So that means new procedures.</p>

            <ol>
              <li>
                Please arrive 15 mins before your open gym time, dressed in your
                fitness gear as the change rooms are closed. Also no out door
                shoes allowed on the course.
              </li>
              <li>
                Please remain in your car till a staff member directs you into
                the building. Your car is the new waiting room. We will be
                processing 3 people at a time.
              </li>
              <li>Once inside please sanitize hands and stay 6 feet apart.</li>
              <li>
                You are required to check in at the front desk where a staff
                member has just a few questions for you.
              </li>
              <li>
                Once you are done at front desk head into gym where our staff
                will confirm the rules of the gym. Stay 6 feet apart follow the
                arrows on the floor, no more than one person on an obstacle at a
                time.
              </li>
              <li>
                Liquid chalk is mandatory for everyone on an obstacle course. It
                contains 99% alcohol and is good for 100 uses, cost is $15.
              </li>
              <li>
                Water station is closed, please bring your own water bottle. If
                you don’t have one, we have some for sale #SupportLocal
              </li>
              <li>Once your time is done please exit by the warped wall.</li>
            </ol>

            <p>
              Thank you for your patience, support and above all else the honour
              of being your fitness provider.
            </p>

            <p>
              Yours in fitness,
              <br />
              Team OCRA
            </p>
          </div>
          <OcrSeparator />

          <h2>06 Cleaning procedures</h2>
          <div className="indented">
            <p>
              1.1 Before and after open gym staff will take cleaner, facemask
              and gloves and proceed around the obstacle course and wipe down
              every obstacle in the gym starting with:
            </p>
            <ul>
              <li>
                Wrestling matt, use mob and disinfectant provided and wipe down
                all matts
              </li>
              <li>Wipe down top of warped wall</li>
              <li>Wipe down top of every wall</li>
              <li>Wipe down slip ramp</li>
              <li>Take out latter and wipe down ropes from tape line down</li>
              <li>Wipe down green rig</li>
              <li>Wipe down monkey bars on green rig</li>
              <li>Wipe down multi rig</li>
              <li>Set up boxes and wipe down monkey bars on silver rig</li>
              <li>Move boxes to middle rig and wipe down all attachments</li>
              <li>Move foam box only to Black matt and wipe down all rings</li>
              <li>Wipe down all handles to doors</li>
              <li>Wipe down Washroom</li>
            </ul>

            <p>
              1.2 Cleaning before during and after group classes. Clients will
              be instructed to wipe down their equipment after each use before
              thy put equipment back. Once Class is over, the Staff will go and
              wipe down the obstacle course and the floors with mop provided.
            </p>

            <p>
              1.3 Before and after each class min 2 staff are cleaning at same
              time, at the 15 min mark and a staff member will open door and
              start registering clients at front desk once cleaning is 75% done,
              min 3 people at a time.
            </p>

            <ul>
              <li>
                Staff will clean the floors with mop and disinfectant after the
                class has finished
              </li>
              <li>
                Staff will clean obstacles that were used during the class (Only
                one section of the obstacle course is permitted to be open
                during each class.
              </li>
              <li>Wipe down washroom and all door handles.</li>
            </ul>
          </div>
          <OcrSeparator />

          <h2>07 Fitness Program Design</h2>
          <div className="indented">
            <p>Each class must be broken into 6 stages.</p>

            <ol>
              <li>
                Stage 1 is a body weight movement to warm up on the black matt,
                this should take 5-6 mins
              </li>
              <li>
                Stage 2 is a strength component, where clients will be directed
                to designated area to complete this first stage which should
                take 15 mins, weights or boxes are not permitted on the black
                matt
              </li>
              <li>
                Stage 3 will be in the same area as the squat rack, you will be
                issued an area for you to work in only, no one else. This stage
                will require dumbbells, KBs and a box.
              </li>
              <li>
                Stage 4 Move back to the Black matt for a body weight
                conditioning work out.
              </li>
              <li>
                Stage 5 Only one section of the obstacle course is permitted to
                be open at a time, so for programing these are the obstacles you
                can select Ninja steps and Jerry cans can be included in every
                work out:
                <ul>
                  <li>Row of walls</li>
                  <li>Slip Ramp</li>
                  <li>Traverse wall/Atlas Wall/Dip Walk</li>
                  <li>Rope climb or swing</li>
                  <li>Ring Swing</li>
                  <li>Monkey bar/Multi Rig</li>
                </ul>
              </li>
              <li>Stage 6 will be the cool down on the black mat. </li>
              <li>
                Cardio equipment. We will have each cardio equipment spaced
                throughout the gym, if you are programing it in, make sure that
                no one can cross contaminate.
              </li>

              <li>
                For the kids work out, will be basically a child version of the
                classes, Master the body before we can add weight, so if a kid
                can not do a body weight squat we will work on mechanics first,
                then work way to Goblet squat with low KB weights, then get them
                to the bar. Until we get smaller bars, lets stick with Goblets.
                As well with the obstacle course we will only be opening up a
                section each day, and that obstacle should tie into the theme
                for that day Strength, Grip, Explosive, Stamina.
              </li>
            </ol>
          </div>
          <OcrSeparator />
          <h2>08 Class attendance</h2>
          <div className="indented">
            <ol>
              <li>
                We have a max attendance for fitness classes of 11 people in the
                gym at all times, this includes staff. For Summer camp it is 10,
                2 staff to 8 kids. We only have 9 working stations for strength,
                everyone can have their own work space for body weight.
              </li>
              <li>
                If a client wants to come in and do own thing while maintaining
                social distancing, we can do that but no more than 9 people per
                class. Everyone will be required to sign in, if they don’t sign
                in we cannot guarantee they can come in and train at that time.
              </li>
              <li>
                Open gym, each client will be instructed to sign in on the app,
                they can pay once they arrive.
              </li>
              <li>Summer camp will be pre registered for each day.</li>
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Rules

export const query = graphql`
  {
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
